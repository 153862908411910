import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Puller } from "./SwipeableDrawerComponents";
import Box from "@mui/material/Box";
import "../../../Styles/v2/DrawerModal.css";

const DrawerComponent = ({
  openState,
  toggleOpenState,
  children,
  title,
  showCloseButton = true,
}) => {
  return (
    <SwipeableDrawer
      className="DrawerComponent"
      keepMounted
      variant="temporary"
      anchor="bottom"
      open={openState}
      onOpen={toggleOpenState(true)}
      onClose={showCloseButton ? toggleOpenState(false) : toggleOpenState(true)}
    >
      <Box className="drawer_container">
        <div className="drawer-title-container">
          <Puller />
          <div className="drawer-title-text">{title}</div>
        </div>
        <div className="drawer-body-container">{children}</div>
      </Box>
    </SwipeableDrawer>
  );
};

export default DrawerComponent;
