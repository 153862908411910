import { useState } from "react";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import "../../../Styles/v2/StepperTutorial.css";
import MuiButtons from "./MuiButtons";
import { Stack } from "@mui/material";
import { steps } from "./StepperTutsList";
import Backdrop from "@mui/material/Backdrop";

const StepperTutorial = ({ base, onFinish, open }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = steps[base].length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Backdrop
      className="StepperTutorialComponent"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Box className="stepper-tutorial-box">
        <div className="stepper-title-box">Useful Tips</div>
        <Stack spacing={2} className="stepper-body-container">
          <div className="stepper-helper">{steps[base][activeStep]?.title}</div>
          {steps[base][activeStep].content}
        </Stack>
        <MobileStepper
          variant="progress"
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            activeStep === maxSteps - 1 ? (
              <MuiButtons
                label="Done"
                type="button"
                buttonType="201"
                fontWeight={600}
                onClick={onFinish}
              />
            ) : (
              <MuiButtons
                label="Next"
                disabled={activeStep === maxSteps - 1}
                type="button"
                variant="outlined"
                fontWeight={600}
                onClick={handleNext}
              />
            )
          }
          backButton={
            <MuiButtons
              label="Back"
              disabled={activeStep === 0}
              type="button"
              variant="outlined"
              fontWeight={600}
              onClick={handleBack}
            />
          }
        />
      </Box>
    </Backdrop>
  );
};

export default StepperTutorial;
