import Switch from "./Switch";
import Tip from "./Tip";
import { Stack } from "@mui/material";

const ToggleTutorialButton = ({ checked, onChange }) => {
  return (
    <Tip alignment="center">
      <Stack direction="row" alignItems="center" spacing={1}>
        <div>Show Tutorial videos button</div>
        <div>
          <Switch onChange={onChange} checked={checked} />
        </div>
      </Stack>
    </Tip>
  );
};

export default ToggleTutorialButton;
