import "../../../Styles/v2/AppMenu.css";
import { Stack, Box } from "@mui/material";
import { NavLink } from "react-router-dom";
import { menuList, bottomMenuList, TutorialMenuItem } from "./AppMenuLists";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import { TutorialFrameItem } from "./TutorialFrameItem";
import { TutorialVideos } from "./TutorialVideoList";
import { tutorialVideos } from "../../../Styles/v2/box";
import Tip from "./Tip";
import Drawer from "./Drawer";
import Fab from "@mui/material/Fab";
import { useSelector } from "react-redux";
import Slide from "@mui/material/Slide/Slide";

const AppMenu = ({ base }) => {
  const { toggleTutorialButton } = useSelector((state) => state);
  const [openTutotialVideos, setOpenTutorialVideos] = useState(false);
  const toggleOpenTutorialVideos = (open) => () => {
    setOpenTutorialVideos(open);
  };

  return (
    <div>
      <Modal
        title="Tutorial Videos"
        openState={openTutotialVideos}
        toggleOpenState={toggleOpenTutorialVideos}
        boxStyle={tutorialVideos}
        modalClassName="tutorial_modal_container"
        direction="left"
      >
        {openTutotialVideos && (
          <Box>
            <Tip>
              <div>
                Can't find the video you are looking for? Send an email to{" "}
                <strong>
                  <a
                    style={{ color: "#000" }}
                    href="mailto:hello@theafricanexporter.com"
                  >
                    hello@theafricanexporter.com
                  </a>
                </strong>
              </div>
            </Tip>
            <Stack marginY={2} spacing={5}>
              {TutorialVideos[base]?.map((video, index) => (
                <TutorialFrameItem
                  height={250}
                  fontSize="medium"
                  key={index}
                  title={video?.title}
                  iframeSrc={video?.iframeSrc}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Modal>

      <menu className="app-menu">
        <div className="app-menu-items">
          {menuList[base]?.map((menuLink, index) => (
            <div key={index} style={{ marginBottom: 15 }}>
              <NavLink
                end
                className="app-menu-item"
                to={`/${base}/${menuLink.to}`}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Stack
                    className="app-menu-icon"
                    direction="row"
                    alignItems="center"
                  >
                    {menuLink.icon}
                  </Stack>
                  <div className="app-menu-label">{menuLink.label}</div>
                </Stack>
              </NavLink>
            </div>
          ))}
          {base !== "admin" && (
            <Slide in={toggleTutorialButton} direction="up">
              <div className="app-menu-item app-menu-tutorial-item">
                <Stack
                  onClick={toggleOpenTutorialVideos(true)}
                  direction="row"
                  alignItems="center"
                  spacing={2}
                >
                  <Stack
                    className="app-menu-icon"
                    direction="row"
                    alignItems="center"
                  >
                    {TutorialMenuItem.icon}
                  </Stack>
                  <div className="app-menu-label">{TutorialMenuItem.label}</div>
                </Stack>
              </div>
            </Slide>
          )}
        </div>
      </menu>
    </div>
  );
};

export const AppBottomMenu = ({ base }) => {
  const { toggleTutorialButton } = useSelector((state) => state);
  const [openTutotialVideos, setOpenTutorialVideos] = useState(false);
  const toggleOpenTutorialVideos = (open) => () => {
    setOpenTutorialVideos(open);
  };
  const pathname = window.location.pathname;
  const [value, setValue] = useState(pathname);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <div>
        <Drawer
          title="Tutorial Videos"
          openState={openTutotialVideos}
          toggleOpenState={toggleOpenTutorialVideos}
        >
          {openTutotialVideos && (
            <Box>
              <Tip>
                <div>
                  Can't find the video you are looking for? Send an email to{" "}
                  <strong>
                    <a
                      style={{ color: "#000" }}
                      href="mailto:hello@theafricanexporter.com"
                    >
                      hello@theafricanexporter.com
                    </a>
                  </strong>
                </div>
              </Tip>
              <Stack marginY={2} spacing={5}>
                {TutorialVideos[base]?.map((video, index) => (
                  <TutorialFrameItem
                    height={200}
                    fontSize="small"
                    key={index}
                    title={video?.title}
                    iframeSrc={video?.iframeSrc}
                  />
                ))}
              </Stack>
            </Box>
          )}
        </Drawer>
      </div>
      {base !== "admin" && (
        <Slide in={toggleTutorialButton} direction="up">
          <Stack
            className="app-bottom-menu-tutorial-item"
            position="fixed"
            right={10}
            bottom="95px"
          >
            <Fab
              className="app-bottom-menu-tutorial-icon"
              onClick={toggleOpenTutorialVideos(true)}
            >
              {TutorialMenuItem.icon}
            </Fab>
          </Stack>
        </Slide>
      )}
      <menu
        className="app-bottom-menu"
        style={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
      >
        <BottomNavigation showLabels value={value} onChange={handleChange}>
          {bottomMenuList[base]?.map((menuLink, index) => (
            <BottomNavigationAction
              key={index}
              component={Link}
              to={`/${base}/${menuLink.to}`}
              value={`/${base}/${menuLink.to}`}
              label={menuLink.label}
              icon={menuLink.icon}
            />
          ))}
        </BottomNavigation>
      </menu>
    </div>
  );
};

export default AppMenu;
