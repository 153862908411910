import { Box, Stack } from "@mui/material";
import "../../../Styles/v2/TutorialFramItem.css";

export const TutorialFrameItem = ({
  title,
  iframeSrc,
  height = 500,
  fontSize = "larger",
}) => (
  <Stack spacing={1}>
    <div
      style={{
        fontWeight: 500,
        fontSize: fontSize,
        color: "gray",
        textTransform: "capitalize",
      }}
    >
      {title}
    </div>
    <Box
      className="tutorialIframeVideo"
      height={height}
      style={{
        overflow: "clip",
        borderRadius: 5,
        position: "relative",
        background: "gray",
        border: "2px solid #c5c5c5",
      }}
    >
      <iframe
        frameBorder={0}
        width="100%"
        height="100%"
        src={iframeSrc}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </Box>
  </Stack>
);
