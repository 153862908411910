import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { IconButton, Stack } from "@mui/material";
import { Close } from "@mui/icons-material/";
import Slide from "@mui/material/Slide/Slide";
import "../../../Styles/v2/DrawerModal.css";

const ModalComponent = ({
  openState,
  toggleOpenState,
  children,
  title,
  boxStyle,
  moreModalClassName,
  showTitleContainer = true,
  showCloseButton = true,
  modalClassName = "modal_container",
  direction = "up",
}) => {
  return (
    <Modal
      className={`ModalComponent ${moreModalClassName}`}
      open={openState}
      onClose={showCloseButton ? toggleOpenState(false) : toggleOpenState(true)}
      keepMounted
    >
      <Slide style={boxStyle} in={openState} direction={direction}>
        <Box className={modalClassName}>
          {showTitleContainer && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className="modal_title_container"
            >
              <div>{title}</div>
              {showCloseButton && (
                <IconButton
                  onClick={toggleOpenState(false)}
                  sx={{ padding: 0.1 }}
                >
                  <Close />
                </IconButton>
              )}
            </Stack>
          )}
          <div className="modal_body_container">{children}</div>
        </Box>
      </Slide>
    </Modal>
  );
};

export default ModalComponent;
