export const productSpecifications = {
  "Teak Round Logs": [
    {
      required: true,
      type: "text",
      name: "length",
      label: "Length",
      defaultUnit: null,
      units: { name: "lengthUnit", values: ["cm", "m"] },
      inputProps: null,
    },
    {
      required: true,
      type: "text",
      name: "diameter",
      label: "Diameter",
      defaultUnit: {
        value: "cm",
      },
      units: { name: "diameterUnit", values: ["cm"] },
      inputProps: null,
    },
    {
      required: true,
      type: "number",
      name: "quantity",
      label: "Quantity",
      defaultUnit: {
        value: "20ft Container",
      },
      units: { name: "quantityUnit", values: ["20ft Container"] },
      inputProps: { min: 1 },
    },
    {
      required: false,
      type: "text",
      name: "information",
      label: "Additional Information",
      defaultUnit: null,
      units: null,
      inputProps: null,
    },
  ],

  "Teak Square Logs": [
    {
      required: true,
      type: "text",
      name: "length",
      label: "Length",
      defaultUnit: null,
      units: { name: "lengthUnit", values: ["cm", "m"] },
      inputProps: null,
    },
    {
      required: true,
      type: "text",
      name: "thickness",
      label: "Thickness",
      defaultUnit: null,
      units: { name: "thicknessUnit", values: ["mm", "cm"] },
      inputProps: null,
    },
    {
      required: true,
      type: "text",
      name: "width",
      label: "Width",
      defaultUnit: null,
      units: { name: "widthUnit", values: ["mm", "cm"] },
      inputProps: null,
    },
    {
      required: true,
      type: "number",
      name: "quantity",
      label: "Quantity",
      defaultUnit: {
        value: "20ft Container",
      },
      units: { name: "quantityUnit", values: ["20ft Container"] },
      inputProps: { min: 1 },
    },
    {
      required: true,
      type: "select",
      name: "dryingLabel",
      label: "Drying",
      defaultUnit: null,
      units: null,
      menu: ["Air Drying", "Kiln Drying", "Fresh Sawn"],
      inputProps: null,
    },
    {
      required: false,
      type: "text",
      name: "information",
      label: "Additional Information",
      defaultUnit: null,
      units: null,
      inputProps: null,
    },
  ],
};
