import { createSlice } from "@reduxjs/toolkit";

const initialState = true;

const toggleTutorialButtonReducer = createSlice({
  name: "toggleTutorialButton",
  initialState,
  reducers: {
    toggleButtonState: (state) => !state,
  },
});

export const { toggleButtonState } = toggleTutorialButtonReducer.actions;
export default toggleTutorialButtonReducer.reducer;
