import { Stack } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material/";
import "../../../Styles/v2/Tip.css";

export const Tip = ({
  message,
  children,
  smallText = true,
  alignment = "flex-start",
  showIcon = true,
}) => {
  return (
    <Stack
      direction="row"
      alignItems={alignment}
      className="tip_component"
      spacing={2}
    >
      {showIcon && <ErrorOutline sx={{ color: "#ee9b00" }} />}
      <div
        style={smallText ? {} : { fontSize: "medium", fontWeight: "normal" }}
      >
        {children ?? message}
      </div>
    </Stack>
  );
};

export default Tip;
