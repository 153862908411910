import { Stack } from "@mui/material";
import buyer1 from "../../../Assets/TutAssist/buyer1.jpg";
import buyer2 from "../../../Assets/TutAssist/buyer2.jpg";
import buyer3 from "../../../Assets/TutAssist/buyer3.jpg";

import mutual1 from "../../../Assets/TutAssist/mutual4.jpg";
import mutual2 from "../../../Assets/TutAssist/mutual2.jpg";
import mutual3 from "../../../Assets/TutAssist/mutual3.jpg";

import merchant1 from "../../../Assets/TutAssist/merchant1.jpg";
import merchant2 from "../../../Assets/TutAssist/merchant2.jpg";
import merchant3 from "../../../Assets/TutAssist/merchant3.jpg";

const StepperRow = ({ index, title, image }) => (
  <Stack spacing={1} className="stepper-body-content">
    <Stack direction="row" alignItems="flex-start" spacing={1}>
      <div>{index}.</div>
      <div>{title}</div>
    </Stack>
    <div className="stepper-body-image">
      <img src={image} alt="" />
    </div>
  </Stack>
);

export const steps = {
  buyer: [
    {
      title: "Where to find Tutorial videos on Home page",
      content: (
        <StepperRow
          image={buyer1}
          title="Locate the Buyers link in the Footer"
          index={1}
        />
      ),
    },
    {
      title: "Where to find Tutorial Videos on Home page",
      content: (
        <StepperRow
          image={buyer2}
          title="Scroll down to find the Tutorial videos"
          index={2}
        />
      ),
    },
    {
      title: "Where to find Tutorial Videos in Dashboard",
      content: (
        <StepperRow
          image={mutual1}
          title="Navigate to the left bottom corner of your screen to find the Tutorial videos button"
          index={1}
        />
      ),
    },
    {
      title: "Where to find Tutorial Videos in Dashboard",
      content: (
        <StepperRow
          image={buyer3}
          title="Clicking the Tutorial button reveals the Tutorial videos"
          index={2}
        />
      ),
    },
    {
      title: "How to hide Tutorial Videos button in Dashboard",
      content: (
        <StepperRow
          image={mutual3}
          title="Go to Settings page by clicking on the Settings button in the menu"
          index={1}
        />
      ),
    },
    {
      title: "How to hide Tutorial Videos button in Dashboard",
      content: (
        <StepperRow
          image={mutual2}
          title="Find the toggle button at the bottom of your screen and click to show/hide the Tutorial button"
          index={2}
        />
      ),
    },
  ],

  merchant: [
    {
      title: "Where to find Tutorial Videos on Home page",
      content: (
        <StepperRow
          image={merchant1}
          title="Locate the Buyers link in the Footer"
          index={1}
        />
      ),
    },
    {
      title: "Where to find Tutorial Videos on Home page",
      content: (
        <StepperRow
          image={merchant2}
          title="Scroll down to find the Tutorial videos"
          index={2}
        />
      ),
    },
    {
      title: "Where to find Tutorial Videos in Dashboard",
      content: (
        <StepperRow
          image={mutual1}
          title="Navigate to the left bottom corner of your screen to find the Tutorial videos button"
          index={1}
        />
      ),
    },
    {
      title: "Where to find Tutorial Videos in Dashboard",
      content: (
        <StepperRow
          image={merchant3}
          title="Clicking the Tutorial button reveals the Tutorial videos"
          index={2}
        />
      ),
    },
    {
      title: "How to hide Tutorial Videos button in Dashboard",
      content: (
        <StepperRow
          image={mutual3}
          title="Go to Settings page by clicking on the Settings button in the menu"
          index={1}
        />
      ),
    },
    {
      title: "How to hide Tutorial Videos button in Dashboard",
      content: (
        <StepperRow
          image={mutual2}
          title="Find the toggle button at the bottom of your screen and click to show/hide the Tutorial button"
          index={2}
        />
      ),
    },
  ],
};
